import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconFile.module.css';

const IconFile = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 23C15 25.1217 15.8429 27.1566 17.3432 28.6569C18.8435 30.1571 20.8783 31 23 31C25.1218 31 27.1566 30.1571 28.6569 28.6569C30.1572 27.1566 31 25.1217 31 23C31 20.8783 30.1572 18.8434 28.6569 17.3431C27.1566 15.8429 25.1218 15 23 15C20.8783 15 18.8435 15.8429 17.3432 17.3431C15.8429 18.8434 15 20.8783 15 23Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23 27V19" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M23.0001 19L20.0001 22"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M23 19L26 22" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.00007 9H19.0001" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M5.00007 15H13.0001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00007 21H10.0001"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 27H3C2.46957 27 1.96086 26.7893 1.58579 26.4142C1.21071 26.0391 1 25.5304 1 25V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17.172C17.7021 1.00011 18.2104 1.21064 18.5853 1.58533L22.4147 5.41467C22.7894 5.7896 22.9999 6.29794 23 6.828V10"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

IconFile.defaultProps = {
  rootClassName: null,
  className: null,
};

IconFile.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconFile;
