import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconVerified.module.css';

const IconVerified = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 775.97 775.97">
      <path d="M734.83,187.73c24.03,41.63-46.78,115.09-40.89,159.95,6.1,46.51,92.86,99.35,80.9,143.96-11.95,44.6-113.5,46.99-142.04,84.22-27.53,35.91-2.93,134.93-44.56,158.97-41.63,24.03-115.09-46.78-159.95-40.89-46.51,6.1-99.35,92.86-143.96,80.9-44.6-11.95-46.99-113.5-84.22-142.04-35.91-27.53-134.93-2.93-158.97-44.56-24.03-41.63,46.78-115.09,40.89-159.95-6.1-46.51-92.86-99.35-80.9-143.96,11.95-44.6,113.5-46.99,142.04-84.22,27.53-35.91,2.93-134.93,44.56-158.97,41.63-24.03,115.09,46.78,159.95,40.89,46.51-6.1,99.35-92.86,143.96-80.9,44.6,11.95,46.99,113.5,84.22,142.04,35.91,27.53,134.93,2.93,158.97,44.56Z" />
      <polygon
        fill="var(--colorWhite)"
        points="340.63 533.98 208.11 402.14 260.77 349.21 338.94 426.98 513.56 241.98 567.86 293.24 340.63 533.98"
      />
    </svg>
  );
};

IconVerified.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconVerified.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconVerified;
