import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.module.css';

const IconCalendar = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="42"
      height="42"
      viewBox="0 0 195.85 204.2"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M192.94,66.15v-27.77c0-8.62-6.99-15.6-15.6-15.6h-5.25" />
      <path d="M25.1,22.78h-6.59c-8.62,0-15.6,6.99-15.6,15.6v27.77" />
      <path d="M2.91,66.15v119.54c0,8.62,6.99,15.6,15.6,15.6h158.83c8.62,0,15.6-6.99,15.6-15.6v-119.54" />
      <line x1="171.48" y1="65.7" x2="24.37" y2="65.7" />
      <path d="M89.05,33.78V11.78c0-4.9,3.97-8.88,8.87-8.88h0c4.9,0,8.88,3.97,8.88,8.88v22c0,4.9-3.97,8.88-8.88,8.88h0c-4.9,0-8.87-3.97-8.87-8.88Z" />
      <path d="M38.84,33.78V11.78c0-4.9,3.97-8.88,8.88-8.88h0c4.9,0,8.88,3.97,8.88,8.88v22c0,4.9-3.97,8.88-8.88,8.88h0c-4.9,0-8.88-3.97-8.88-8.88Z" />
      <path d="M141.05,33.78V11.78c0-4.9,3.97-8.88,8.88-8.88h0c4.9,0,8.88,3.97,8.88,8.88v22c0,4.9-3.97,8.88-8.88,8.88h0c-4.9,0-8.88-3.97-8.88-8.88Z" />
      <rect x="33.34" y="93.77" width="27.76" height="27.76" />
      <rect x="33.34" y="145.62" width="27.76" height="27.76" />
      <rect x="84.04" y="93.77" width="27.76" height="27.76" />
      <rect x="84.04" y="145.62" width="27.76" height="27.76" />
      <rect x="134.74" y="93.77" width="27.76" height="27.76" />
      <rect x="134.74" y="145.62" width="27.76" height="27.76" />
      <line x1="70.25" y1="22.78" x2="88.17" y2="22.78" />
      <line x1="120.05" y1="22.78" x2="141.05" y2="22.78" />
    </svg>
  );
};

const { string } = PropTypes;

IconCalendar.defaultProps = {
  className: null,
  rootClassName: null,
};

IconCalendar.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconCalendar;
