import classNames from 'classnames';
import React from 'react';
import { IconClose, InlineTextButton, IconFile } from '..';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import css from './FileItem.module.css';

const FileItem = props => {
  const { rootClassName, className, handleRemoveFile, file } = props;
  const { name } = file;

  const classes = classNames(rootClassName || css.root, className);

  const isExpired = file.expiresAt ? file.expiresAt <= moment.utc().valueOf() : false;

  const handlePreviewFile = file => {
    const url = file.url || file.original ? file.url || file.original : URL.createObjectURL(file);
    window.open(url);
  };

  return (
    <div className={classes}>
      <label className={css.file} onClick={() => !isExpired && handlePreviewFile?.(file)}>
        <IconFile className={css.iconFile} />
        {isExpired ? <FormattedMessage id="General.expired" /> : null}
        <span className={classNames({ [css.expired]: isExpired })}>{name}</span>
      </label>
      {handleRemoveFile && (
        <InlineTextButton onClick={() => handleRemoveFile(file)} type="button">
          <IconClose className={css.iconClose} />
        </InlineTextButton>
      )}
    </div>
  );
};

export default FileItem;
