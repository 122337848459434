import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { requestIdentityVerification } from '../../util/api';

// ================ Action types ================ //

export const SUBMIT_IDENTITY_VERIFICATION_REQUEST =
  'app/IdentityVerificationPage/SUBMIT_IDENTITY_VERIFICATION_REQUEST';
export const SUBMIT_IDENTITY_VERIFICATION_SUCCESS =
  'app/IdentityVerificationPage/SUBMIT_IDENTITY_VERIFICATION_SUCCESS';
export const SUBMIT_IDENTITY_VERIFICATION_ERROR =
  'app/IdentityVerificationPage/SUBMIT_IDENTITY_VERIFICATION_ERROR';

// ================ Reducer ================ //

const initialState = {
  inProgress: false,
  ready: false,
  error: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case SUBMIT_IDENTITY_VERIFICATION_REQUEST:
      return { ...state, inProgress: true, ready: false, error: null };
    case SUBMIT_IDENTITY_VERIFICATION_SUCCESS:
      return { ...state, inProgress: false, ready: true };
    case SUBMIT_IDENTITY_VERIFICATION_ERROR:
      return { ...state, inProgress: false, error: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const submitIdentityVerificationRequest = () => ({
  type: SUBMIT_IDENTITY_VERIFICATION_REQUEST,
});
export const submitIdentityVerificationSuccess = () => ({
  type: SUBMIT_IDENTITY_VERIFICATION_SUCCESS,
});
export const submitIdentityVerificationError = error => ({
  type: SUBMIT_IDENTITY_VERIFICATION_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const submitIdentityVerification = (values, userId) => async (dispatch, getState, sdk) => {
  dispatch(submitIdentityVerificationRequest());

  const formData = new FormData();
  const files = Object.values(values);

  formData.append('userId', userId.uuid);
  for (let i = 0; i < files?.length || 0; i++) {
    formData.append('files', files[i]);
  }

  return requestIdentityVerification(formData)
    .then(() => {
      dispatch(submitIdentityVerificationSuccess());
      dispatch(fetchCurrentUser());
    })
    .catch(e => dispatch(submitIdentityVerificationError(e)));
};

export const loadData = () => {
  return fetchCurrentUser();
};
