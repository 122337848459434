import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconWork.module.css';

const IconWork = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="48"
      height="42"
      viewBox="0 0 241.32 212.53"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M84.08,137.41c-.29-9.86,9.07-19.36,17.77-22.35,5.61-1.93,9.55-3,19.74-2.96,10.18.04,14.1,1.16,19.7,3.13,8.69,3.06,18,12.62,17.65,22.48" />
      <path d="M102.64,77.68c.04,10.39,8.48,18.78,18.87,18.74,10.39-.04,18.78-8.48,18.74-18.87-.04-10.39-8.48-18.78-18.87-18.74-10.39.04-18.78,8.48-18.74,18.87Z" />
      <polyline points="40.7 102.63 40.7 208.78 61.16 208.78" />
      <polyline points="200.62 102.63 200.62 208.78 180.13 208.78" />
      <polyline points="40.71 49.91 40.71 6.47 71.98 6.47 71.98 22.87" />
      <path d="M25.48,115.36l-3.79,3.23c-2.4,2.04-5.98,1.8-8.09-.53l-9.2-10.17c-2.2-2.43-1.96-6.19.53-8.32L115.31,4.89c3.08-2.64,7.62-2.64,10.7,0l110.37,94.68c2.48,2.13,2.72,5.89.53,8.32l-9.2,10.17c-2.11,2.33-5.69,2.57-8.09.53l-3.79-3.23" />
      <path d="M156.97,209.62h-72.66c-2.72,0-5.04-1.98-5.46-4.66l-7.77-49.09c-.44-2.77,1.7-5.27,4.5-5.27h90.12c2.8,0,4.94,2.5,4.5,5.27l-7.77,49.09c-.43,2.69-2.74,4.66-5.46,4.66Z" />
      <path d="M130.21,180.43c0,5.28-4.28,9.56-9.56,9.56s-9.56-4.28-9.56-9.56,4.28-9.56,9.56-9.56" />
    </svg>
  );
};

const { string } = PropTypes;

IconWork.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWork.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWork;
