import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { isUserProvider } from '../../util/userHelpers';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = () => {
  const { footer = {}, topbar } = useConfiguration();

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }

  const { currentUser } = useSelector(state => state.user);
  const isProvider = isUserProvider(currentUser);

  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...{
      ...footer,
      blocks: footer.blocks.map(block =>
        isProvider
          ? block
          : {
              ...block,
              text: {
                ...block.text,
                content: block.text.content.replace('- [Post a new listing](/l/new)\n', ''),
              },
            }
      ),
    },
    sectionId: 'footer',
    sectionType: 'footer',
    linkLogoToExternalSite: topbar?.logoLink,
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
// const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
// export default FooterContainer;

export default FooterComponent;
