import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconIdentityVerified.module.css';

const IconIdentityVerified = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1120.39 1040.58"
    >
      <path d="M560.27,434.58c119.81,0,217.29-97.48,217.29-217.29S680.08,0,560.27,0s-217.29,97.48-217.29,217.29,97.48,217.29,217.29,217.29ZM437.67,204.31c5.61-5.61,14.7-5.61,20.31,0l66.69,66.68,137.86-137.86c5.61-5.61,14.7-5.61,20.31,0,5.61,5.61,5.61,14.7,0,20.31l-148.02,148.02c-2.81,2.81-6.47,4.21-10.16,4.21s-7.35-1.4-10.16-4.21l-76.85-76.83c-5.61-5.61-5.61-14.7,0-20.31Z" />
      <path d="M1049.11,310.75h-261.37c-36.88,89.42-124.9,152.56-227.47,152.56s-190.59-63.14-227.47-152.56H71.3c-39.31,0-71.3,31.98-71.3,71.28v587.26c0,39.31,31.98,71.3,71.3,71.3h977.81c39.3,0,71.28-31.98,71.28-71.3V382.03c0-39.3-31.98-71.28-71.28-71.28ZM77.43,949.87c2.99-66.45,23.96-96.39,44.2-111.21,38.09-27.88,92.55-16.02,125.37-59.62,6.94-9.21,10.81-18.11,12.97-24.07-20.76-20.89-32.61-45.35-42.16-72.99-20.68-7.32-33.05-26.97-30.45-45.73,2.88-20.75,22.71-30.57,24.33-31.34-1.15-9.55-4.59-45.09,18.34-71.97,17.2-20.38,44.71-30.57,72.23-30.57s55.02,10.19,72.21,30.57c22.93,26.88,19.49,62.42,18.34,71.97,1.62.77,21.45,10.58,24.33,31.34,2.6,18.76-9.76,38.41-30.45,45.73-9.55,27.64-21.4,52.1-42.16,72.99,2.16,5.95,6.03,14.85,12.97,24.07,32.82,43.6,87.28,31.75,125.37,59.62,20.24,14.81,41.21,44.76,44.2,111.21H77.43ZM830.47,890.66h-201.26c-7.93,0-14.36-6.43-14.36-14.36s6.43-14.36,14.36-14.36h201.26c7.93,0,14.36,6.43,14.36,14.36s-6.43,14.36-14.36,14.36ZM1043.16,740.47h-413.88c-7.93,0-14.36-6.43-14.36-14.36s6.43-14.36,14.36-14.36h413.88c7.93,0,14.36,6.43,14.36,14.36s-6.43,14.36-14.36,14.36ZM1043.16,590.38h-413.88c-7.93,0-14.36-6.43-14.36-14.36s6.43-14.36,14.36-14.36h413.88c7.93,0,14.36,6.43,14.36,14.36s-6.43,14.36-14.36,14.36Z" />
    </svg>
  );
};

const { string } = PropTypes;

IconIdentityVerified.defaultProps = {
  className: null,
  rootClassName: null,
};

IconIdentityVerified.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconIdentityVerified;
