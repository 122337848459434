import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconAttachment.module.css';

const IconAttachment = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg className={classes} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.113 7.748h-0.002c-0 0-0.001 0-0.001 0-0.413 0-0.748 0.335-0.749 0.748v0l-0.029 14.197c0.004 0.084 0.006 0.183 0.006 0.282 0 1.812-0.755 3.448-1.967 4.611l-0.002 0.002c-1.134 1.011-2.639 1.629-4.287 1.629-0.030 0-0.059-0-0.088-0.001l0.004 0h-0.015c-0.028 0-0.061 0.001-0.094 0.001-1.64 0-3.136-0.611-4.275-1.618l0.007 0.006c-1.225-1.165-1.988-2.808-1.988-4.629 0-0.090 0.002-0.18 0.006-0.269l-0 0.013v-15.516c-0.003-0.056-0.004-0.122-0.004-0.188 0-2.341 1.898-4.239 4.239-4.239 0.066 0 0.131 0.002 0.196 0.004l-0.009-0c0.056-0.003 0.122-0.004 0.188-0.004 2.341 0 4.238 1.897 4.238 4.238 0 0.065-0.002 0.131-0.004 0.195l0-0.009-0.057 14.276c0.002 0.045 0.003 0.097 0.003 0.15 0 0.85-0.311 1.628-0.826 2.225l0.004-0.004c-0.411 0.439-0.994 0.712-1.641 0.713h-0.020c-0.654-0.006-1.241-0.29-1.648-0.74l-0.002-0.002c-0.489-0.586-0.785-1.347-0.785-2.178 0-0.076 0.002-0.151 0.007-0.225l-0.001 0.010v-10.344c0-0.414-0.336-0.75-0.75-0.75s-0.75 0.336-0.75 0.75v0 10.338c-0.018 0.146-0.029 0.315-0.029 0.486 0 2.221 1.741 4.035 3.932 4.152l0.010 0 0.034 0.002c0.001 0 0.003 0 0.005 0 1.073 0 2.040-0.452 2.721-1.176l0.002-0.002c0.766-0.858 1.235-1.996 1.235-3.244 0-0.053-0.001-0.107-0.003-0.16l0 0.008 0.057-14.283c0-7.775-11.844-7.775-11.844 0v15.518c-0.004 0.097-0.007 0.21-0.007 0.324 0 4.237 3.435 7.672 7.672 7.672 0.063 0 0.127-0.001 0.19-0.002l-0.009 0h0.017c0.024 0 0.053 0 0.082 0 2.038 0 3.898-0.767 5.305-2.029l-0.008 0.007c1.514-1.432 2.457-3.456 2.457-5.699 0-0.106-0.002-0.212-0.006-0.318l0 0.015 0.029-14.193c0-0.001 0-0.001 0-0.002 0-0.414-0.335-0.749-0.748-0.75h-0z"></path>
    </svg>
  );
};

IconAttachment.defaultProps = {
  rootClassName: null,
  className: null,
};

IconAttachment.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconAttachment;
