import React, { Component } from 'react';
import { arrayOf, func, shape } from 'prop-types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isMainSearchTypeKeywords } from '../../util/search';
import { isScrollingDisabled } from '../../ducks/ui.duck';

import {
  Heading,
  Page,
  LayoutSingleColumn,
  H3,
  NamedLink,
  IconCalendar,
  IconWork,
  IconServices,
  IconHowItWorks,
  H4,
} from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import css from './BecomeProviderPage.module.css';

const SECTIONS = [
  // Section I
  {
    heading: 'Become A Rogue Runner!',
    description: [
      'The beginning of the rest of your tasking life starts here.',
      'Whether you’re slipping into remote work from the comfort of your couch or taking on jobs in the wild, this is where freedom meets opportunity.',
    ],
    steps: [
      {
        icon: IconCalendar,
        title: 'Set Your Own Schedule',
        description:
          'Whether you prefer morning tasks, late-night gigs, or only weekends, the choice is yours. Adjust your availability each week and opt-in to same-day jobs whenever youre ready to jump in.',
      },
      {
        icon: IconWork,
        title: 'Choose How You Work',
        description:
          'Take on tasks that suit your lifestyle. Whether you prefer working from home or getting out in the field, we offer both remote and in-person opportunities across multiple categories.',
      },
      {
        icon: IconServices,
        title: 'Curate Your Services',
        description:
          'Pick tasks that match your strengths, or try something new to keep things interesting. With many categories to choose from, you can build a portfolio that fits your style and evolves with you.',
      },
    ],
    data: {
      title: 'Becoming A Rogue Runner: 101',
      description:
        'RogueRunners connects busy people in need of help with trusted local Taskers who can lend a hand with everything from home repairs to errands. As a Tasker, you can get paid to do what you love, when and where you want — all while saving the day for someone in your city.',
      steps: [
        {
          title: '1. Get in the Door',
          description: 'Sign up on our website to create your account and start your registration.',
        },
        {
          title: '2. Map Your Moves',
          description:
            'Choose the services you want to offer and decide whether you’ll take remote tasks, in-person gigs, or both. Your path is yours to design.',
        },
        {
          title: "3. Show Us You're ready ",
          description:
            'Verify your identity and complete any required checks; we’ll also review your social media—discretion and alignment with our standards are a must (18+ to apply, 21+ for select listings).',
        },
        {
          title: '4. Wait for the Green Light',
          description:
            'Once your application is in, we’ll review it and get back to you within 48 hours. Applications are accepted on a rolling basis, so take your time to make it count.',
        },
        {
          title: '5. Call the Shots',
          description:
            'Set your availability and define the areas where you’ll operate. Adjust your schedule weekly and opt into same-day jobs when you want to jump in.',
        },
        {
          title: '6. Hit the Ground Running',
          description:
            'If approved, you’ll be ready to start accepting jobs and building your reputation. Keep things tight—Runners need to maintain a 4-star average to stay active.',
        },
      ],
    },
  },
];

export class BecomeProviderPageComponent extends Component {
  render() {
    const { intl, scrollingDisabled, currentUser } = this.props;

    return (
      <Page
        title={intl.formatMessage({
          id: 'BecomeProviderPage.title',
        })}
        scrollingDisabled={scrollingDisabled}
      >
        <LayoutSingleColumn topbar={<TopbarContainer />} footer={<FooterContainer />}>
          <div className={css.root}>
            <div className={css.content}>
              {SECTIONS.map(({ heading, description, steps, data }, i) => (
                <div key={`section_${i}`} className={css.section}>
                  <div className={css.divider} />

                  <Heading as="h2" rootClassName={css.heading}>
                    {heading}
                  </Heading>
                  {description && (
                    <p>
                      {description.map(d => (
                        <div>{d}</div>
                      ))}
                    </p>
                  )}

                  <div className={css.steps}>
                    {steps.map(({ icon: Icon, title, description }, j) => (
                      <div key={`sect_${i}_step_${j}`} className={css.stepItem}>
                        <Icon />
                        <H3>{title}</H3>
                        <p>{description}</p>
                      </div>
                    ))}
                  </div>

                  <div className={css.details}>
                    <div className={css.imageWrapper}>
                      <IconHowItWorks />
                    </div>
                    <div>
                      <H3 className={css.stepHeader}>{data.title}</H3>
                      {data.steps.map(({ title, description }, j) => (
                        <div key={`sect_${i}_step_${j}`} className={css.stepItem}>
                          <H4>{title}</H4>
                          <p>{description}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className={css.callToAction}>
            {currentUser ? (
              <NamedLink
                className={css.submitButton}
                name="ProfilePage"
                params={{ id: currentUser.id.uuid }}
              >
                Apply Now
              </NamedLink>
            ) : (
              <NamedLink className={css.submitButton} name="SignupPage">
                Apply Now
              </NamedLink>
            )}
          </div>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

BecomeProviderPageComponent.defaultProps = {
  staticContext: {},
};

BecomeProviderPageComponent.propTypes = {
  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const EnhancedBecomeProviderPage = props => {
  const routeConfiguration = useRouteConfiguration();
  const config = useConfiguration();
  const history = useHistory();
  const intl = useIntl();
  const { currentUser } = useSelector(state => state.user);

  return (
    <BecomeProviderPageComponent
      routeConfiguration={routeConfiguration}
      marketplaceName={config.marketplaceName}
      isKeywordSearch={isMainSearchTypeKeywords(config)}
      history={history}
      currentUser={currentUser}
      intl={intl}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const BecomeProviderPage = compose(connect(mapStateToProps))(EnhancedBecomeProviderPage);

export default BecomeProviderPage;
