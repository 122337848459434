import classNames from 'classnames';
import { bool, string } from 'prop-types';
import React, { useMemo, useCallback, Component } from 'react';
import { FileItem } from '..';
import { lazyLoadWithDimensions } from '../../util/uiHelpers.js';

import css from './ListOfFiles.module.css';

class ChatImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}

const ListOfFiles = props => {
  const {
    rootClassName,
    className,
    files,
    handleRemoveFile,
    limitedHeight,
    oneFileItemInRow,
    setImagesOpened,
    chatRef,
  } = props;

  const classes = classNames(
    rootClassName || css.root,
    { [css.limitedHeight]: limitedHeight, [css.oneFileItemInRow]: oneFileItemInRow },
    className
  );

  const isImage = useCallback(fileName => /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(fileName), []);

  const images = useMemo(() => files.filter(f => isImage(f.original?.key)), [files, isImage]);
  const otherFiles = useMemo(() => files.filter(f => !isImage(f.original?.key)), [files, isImage]);

  const LazyImage = useMemo(() => lazyLoadWithDimensions(ChatImage, { elementRef: chatRef }), [
    chatRef,
  ]);

  if (!files || files.length < 1) {
    return null;
  }

  return (
    <div className={classes}>
      <div className={css.images}>
        {images.map((image, i) => (
          <div
            key={`image_${image.original.id}`}
            className={css.imageFileContainer}
            onClick={() =>
              setImagesOpened({
                images: images.map(image => image.original),
                selectedImageIndex: i,
              })
            }
          >
            <div className={css.imageWrapper}>
              <LazyImage
                className={css.imageFile}
                src={image.thumbnail?.url || image.original?.url}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
      {otherFiles.length > 0 &&
        otherFiles.map((file, i) => (
          <FileItem
            className={css.fileItem}
            key={`file_${file.original?.id || file.lastModified}_${i}`}
            handleRemoveFile={handleRemoveFile}
            file={file.original || file}
          />
        ))}
    </div>
  );
};

ListOfFiles.defaultProps = {
  className: null,
  limitedHeight: true,
  oneFileItemInRow: false,
};

ListOfFiles.propTypes = {
  className: string,
  limitedHeight: bool,
  oneFileItemInRow: bool,
};

export default ListOfFiles;
