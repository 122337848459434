/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  // {
  //   "scope": "public",
  //   "label": "Gears",
  //   "key": "gears",
  //   "schemaType": "long",
  //   "numberConfig": {
  //     "minimum": 1,
  //     "maximum": 24
  //   },
  //   "filterConfig": {
  //     "indexForSearch": true,
  //     "group": "primary",
  //     "label": "Gears"
  //   }
  // }
  // {
  //   key: 'bikeType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   categoryConfig: {
  //     limitToCategoryIds: true,
  //     categoryIds: ['cats'],
  //   },
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
  //     label: 'Bike type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Bike type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Bike type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a bike type.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['product-selling'],
  //   },
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['daily-booking'],
  //   },
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

export const LISTING_CATEGORY_DESCRIPTION = [
  {
    id: 'ghost-writer',
    label: 'Ghost Writer',
    description:
      'Our runners expertly bridge the gap between “we need to talk” and “don’t hate me,” one call, text, and DM at a time.',
  },
  {
    id: 'boundary-setter',
    label: 'Boundary Setter',
    description:
      'Need to set clear boundaries with someone but don’t know how to say it without causing friction? We’ll craft a thoughtful, firm message that gets the point across while maintaining the relationship.',
  },
  {
    id: 'breakup-buffer',
    label: 'Breakup Buffer',
    description:
      'It’s not working out, but you don’t know how to say it? We’ll help you craft the right message or handle the call to make a clean, respectful break. We can even help manage the follow-up questions and emotional fallout so you don’t have to. However, if chaos is what you choose, we can post your official breakup announcement, tagging both parties involved.',
  },
  {
    id: 'ghost-texter',
    label: 'Ghost Texter',
    description:
      'Trying to back out of a conversation or relationship without the confrontation? Tell them you got a new number and let us do the ghosting for you. We’ll help you slowly fade out of the conversation with messages that cool things down naturally, avoiding any harsh endings.',
  },
  {
    id: 'resignation-relay',
    label: 'Resignation Relay',
    description:
      'Quitting a job can be tricky, especially if you don’t want to burn bridges. We’ll help you draft a professional, respectful resignation letter or make the difficult goodbye call for you, ensuring a graceful exit from your current role.',
  },
  {
    id: 'cheater-expose',
    label: 'Cheater Expose',
    description:
      'Ready to call someone out for their cheating ways? Our runners craft a post that exposes the truth with just the right amount of drama to make a statement. Whether you want to be direct or drop hints that leave everyone guessing, we make sure the message is clear and the impact is unforgettable. You can let us post it and tag the involved parties, or we can provide you with the post to drop it yourself.',
  },
  {
    id: 'rogue-recon',
    label: 'Rogue Recon',
    description: 'Your personal intel squad, minus the trench coats and wiretaps.',
  },
  {
    id: 'neighborhood-intel',
    label: 'Neighborhood Intel',
    description:
      'Moving to a new area? We gather local insider tips, from the best coffee shops to any neighborhood quirks. We’ll even let you know if it’s dog-friendly or where the hidden gems are.',
  },
  {
    id: 'dm-decoy',
    label: 'DM Decoy',
    description:
      'They say the truth always comes out, but sometimes it needs a little nudge. We’ll pose as a decoy to test your partner’s loyalty—DMs, follows, or even a video call. You choose the bait.',
  },
  {
    id: 'social-sleuth',
    label: 'Social Sleuth',
    description:
      'Want to get a better read on someone’s online vibe? Whether you’re checking out a future roommate, a potential business partner, or someone you’re just curious about, we’ll analyze their public social media presence. Expect a fun, insightful breakdown with screenshots of their top likes, notable follows, and any patterns that help paint a full picture—no overstepping, just recon.',
  },
  {
    id: 'placesaver',
    label: 'Placesaver',
    description:
      'Ever sat on a towel for three hours just to hold a beach spot? No? Good—let’s keep it that way. Whether you need us in a line, at a table, or anywhere else people like to swarm, we’ve got it covered. Arrive when you want to.',
  },
  {
    id: 'exvibes-check',
    label: 'Exvibes Check',
    description:
      'Before getting back in touch with an ex, let us do a little recon. We discreetly check their social presence and lifestyle updates to give you a non-intrusive snapshot of where they’re at now.',
  },
  {
    id: 'lost-and-found',
    label: 'Lost & Found',
    description:
      'Left something behind on vacation or at your friend’s place? Don’t panic. We’ll connect you with a runner nearby to retrieve it. You’ll get your stuff back without the hassle—or the side-eye.',
  },
  {
    id: 'competitor-check',
    label: 'Competitor Check',
    description:
      'Keep an eye on your competition without lifting a finger. We’ll track their latest moves, marketing trends, and customer reviews to give you a competitive advantage.',
  },
  {
    id: 'diversion',
    label: 'Diversion',
    description:
      'Your judgment-free, one-way ticket out of awkward situations, no questions asked.',
  },
  {
    id: 'the-great-escape',
    label: 'The Great Escape',
    description:
      'Stuck in a meeting, date, or dinner that’s dragging on? We’ll send the perfectly timed phone call or text to give you the reason to make a quick exit. No awkward explanations needed.',
  },
  {
    id: 'note-from-mom',
    label: 'Note From Mom',
    description:
      'Need an out that sounds legitimate? We’ll send you a perfectly crafted text or email from a "work emergency" or "family situation" that you can show as proof. It’s convincing, discreet, and ensures a smooth exit from any obligation.',
  },
  {
    id: 'industry-plant',
    label: 'Industry Plant',
    description:
      'Need to command attention at your next event? Our runners work as your personal entourage and secret hype team. Whether it’s a social gathering, networking event, or launch party, we’ll make sure you’re the center of attention. From walking in like a VIP to planting buzz-worthy chatter in the crowd, we create an unforgettable aura around you.',
  },
  {
    id: 'helpsontheway',
    label: 'Helpsontheway',
    description:
      'Need backup at a social or work event? We’ll send a runner to pose as a friend, colleague, or even a client to help smooth over tricky conversations, assist in navigating awkward moments, or reinforce your credibility in a social setting.',
  },
  {
    id: 'runawaybride',
    label: 'Runaway Bride',
    description:
      'Some weddings deserve an unexpected plot twist, and that’s where we come in. Our Runaway Bride service handles those moments when an objection needs to be voiced. Whether it’s delivering a heartfelt plea or a carefully worded intervention, we step in with discretion and just the right amount of impact to make sure it’s memorable—for all the right reasons…we think.',
  },
];
