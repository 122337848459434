import React, { Component, useMemo } from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';

import css from './FieldToggle.module.css';

const handleChange = (propsOnChange, inputOnChange) => event => {
  // If "onChange" callback is passed through the props,
  // it can notify the parent when the content of the input has changed.
  if (propsOnChange) {
    // "handleChange" function is attached to the low level <select> component
    // value of the element needs to be picked from target
    const value = event.nativeEvent.target.checked;
    propsOnChange(value);
  }
  // Notify Final Form that the input has changed.
  // (Final Form knows how to deal with synthetic events of React.)
  inputOnChange(event);
};

const FieldToggleComponent = props => {
  const {
    input,
    id,
    label,
    additionalLabelContent,
    className,
    toggleClassName,
    disabled,
    isBig,
    onChange,
  } = props;

  const { checked, onChange: inputOnChange, ...restOfInput } = input;

  const inputProps = useMemo(
    () => ({
      id,
      type: 'checkbox',
      checked,
      disabled,
      onChange: handleChange(onChange, inputOnChange),
      ...restOfInput,
    }),
    [checked, disabled, id, inputOnChange, onChange, restOfInput]
  );

  return (
    <div className={classNames(css.toggleContainer, className)}>
      <label
        className={classNames(
          { [css.smallSwitch]: !isBig },
          { [css.bigSwitch]: isBig },
          toggleClassName
        )}
        htmlFor={id}
      >
        <input {...inputProps} className={css.input} />
        <span
          className={classNames({ [css.smallSlider]: !isBig, [css.bigSlider]: isBig }, css.round)}
        />
      </label>
      {label && (
        <span className={classNames(css.label, { [css.labelNotChecked]: !checked })}>
          {label}
          {additionalLabelContent}
        </span>
      )}
    </div>
  );
};

class FieldToggle extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldToggleComponent} type="checkbox" {...this.props} />;
  }
}

export default FieldToggle;
