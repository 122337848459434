import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes, { number, shape } from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { IconAttachment } from '..';
import { withViewport } from '../../util/uiHelpers';

import css from './Dropzone.module.css';

export const MAX_FILE_SIZE = 25 * 1024 * 1024; // 25MB

const Dropzone = props => {
  const { className, handleFiles, multiple, accept, name, disabled, maxSize } = props;

  const onDrop = useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) return;
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    accept,
    multiple,
    disabled,
    maxSize,
  });

  const dropzoneClasses = classNames(css.dropzone, className, {
    [css.dropzoneActive]: isDragActive,
    [css.dropzoneAccept]: isDragAccept,
    [css.dropzoneReject]: isDragReject,
    [css.dropzoneDisabled]: disabled,
  });

  return (
    <div {...getRootProps()} className={classNames(dropzoneClasses, className)}>
      <input {...getInputProps()} name={name} />

      <IconAttachment className={css.icon} />
    </div>
  );
};

Dropzone.defaultProps = {
  className: null,
  multiple: true,
  name: 'file',
  accept: ['image/*', 'video/*', 'audio/*', 'application/*'],
  maxSize: MAX_FILE_SIZE,
};

const { func, bool, string, node, arrayOf, oneOfType } = PropTypes;

Dropzone.propTypes = {
  className: string,
  handleFiles: func.isRequired,
  handleButton: node,
  multiple: bool,
  accept: oneOfType([arrayOf(string), string]),
  name: string,
  maxSize: number,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

export default withViewport(Dropzone);
