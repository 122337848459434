import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconServices.module.css';

const IconServices = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="42"
      height="42"
      viewBox="0 0 207.77 221.74"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M116.05,59.79c18.12,0,32.82,14.69,32.82,32.82,0,21.21-15.16,22.52-17.27,40.83l-.9,6.46" />
      <path d="M116.05,59.79c-18.12,0-32.82,14.69-32.82,32.82,0,21.21,15.16,22.52,17.27,40.83l.9,6.46" />
      <line x1="116.02" y1="139.89" x2="116.02" y2="105.51" />
      <line x1="101.83" y1="153.68" x2="130.26" y2="153.68" />
      <path d="M163.59,210.23c-3.93-21.19-4.31-34.48,11.58-50.24,21.68-21.5,29.7-46.59,29.7-67.79,0-49.77-39.52-89.29-88.94-89.29-37.28,0-73.81,20.06-83.77,58.78-1.65,6.43-3,13.35-2.84,19.5.11,3.95-.77,7.87-2.66,11.34L3.65,135.25c-2.01,3.73.25,8.32,4.43,9.01l19.93,3.28,4.25,38.46c.83,7.48,7.43,12.96,14.94,12.39l43.23-3.28c13.26-1,24.08-10.99,26.15-24.12l.58-3.68" />
      <line x1="116.03" y1="39.93" x2="116.03" y2="25.15" />
      <line x1="153.4" y1="55.42" x2="163.86" y2="44.96" />
      <line x1="168.89" y1="92.8" x2="183.67" y2="92.8" />
      <line x1="153.4" y1="130.17" x2="163.86" y2="140.63" />
      <line x1="78.64" y1="130.17" x2="68.19" y2="140.63" />
      <line x1="63.16" y1="92.79" x2="48.38" y2="92.79" />
      <line x1="78.65" y1="55.41" x2="68.19" y2="44.96" />
      <line x1="81.94" y1="207.92" x2="82.98" y2="218.84" />
      <path d="M127.68,93.49c0,6.42-5.21,11.63-11.63,11.63s-11.63-5.21-11.63-11.63" />
    </svg>
  );
};

const { string } = PropTypes;

IconServices.defaultProps = {
  className: null,
  rootClassName: null,
};

IconServices.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconServices;
